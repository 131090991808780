import React from 'react';
import ReactDOM from 'react-dom/client';
//------- Components -------//
import App from './components/App';
//---------- CSS -----------//
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css';
//--------------------------//

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App/>      
    </React.StrictMode>
)
