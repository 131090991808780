import React from 'react'
import { Image } from 'react-bootstrap'
import ritn from '../../assets/Images/ritnTV.png'

const TextDev = () => {
    return (
        <div className='text-dev'>
            <Image
                className='mb-4'
                rounded
                roundedCircle
                fluid
                style={{height: '300px'}}
                src={ritn}
            />
            <h2>Le site de la RitnTV est actuellement fermé !</h2>
            <h5 className='mt-3'>Un nouveau site est en cours de développement.</h5>
        </div>
    )
}

export default TextDev
