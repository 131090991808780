import React from 'react'
import TextDev from '../TextDev'
import OverlayBg from '../OverlayBg'


const App = () => {
    return (
        <div className='main-app'>
            <OverlayBg bgColorPourcent={0.8}/>
            <TextDev/>
        </div>
    )
}

export default App

