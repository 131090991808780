import React from 'react'

const OverlayBg = (bgColorPourcent) => {

    const bgColor = ({bgColorPourcent}) => bgColorPourcent===undefined 
    ? `rgba(0,0,0,.5)`                          // default value
    : `rgba(0,0,0,${bgColorPourcent})`          // props value

    const bgStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: bgColor(bgColorPourcent)
    }

    return <div style={bgStyle}/>
}

export default OverlayBg
